import React from 'react';
import styles from './Section.module.css';
import PropTypes from 'prop-types';

const Section = ({ children, type }) => {
  let section = <div className={styles.section}>{children}</div>;

  if (type === 'dark') {
    section = <div className={styles.sectionDark}>{children}</div>;
}

    if (type === 'blue') {
    section = <div className={styles.sectionBlue}>{children}</div>;
}

    return section;
};

Section.propTypes = {
  type: PropTypes.string,
};

Section.defaultProps = {
  type: 'light',
};

export default Section;
