import React from 'react';
import { Helmet } from 'react-helmet';

import Layout from '../components/Layout/Layout';
import Section from '../components/Section/Section';


const GettingStartedToC: React.FC<PageProps> = () => (
  <Layout>
    <Helmet>
      <title>IEEE SA OPEN</title>
    </Helmet>
    <Section type={'light'}>
    <h1>Table of Contents</h1>
    <h3>Participation</h3>
    <p>
        <ul>
        <li><a href="/participation/#sso">Creating Your IEEE SA OPEN Single Sign On</a></li>
        <li><a href="/participation/#signcla">How To A Sign Contributor License Agreement</a></li>
        </ul>
    </p>
     
    </Section>
  </Layout>
);

export default GettingStartedToC;

